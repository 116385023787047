<template>
  <div class="lesson-video-player" :class="{ vertical }">
    <video
      v-if="isStream"
      controls
      crossorigin
      id="lesson-video-player"
      class="w-100"
      :data-poster="resource.image"
    />

    <div v-else class="ratio ratio-16x9" id="lesson-video-player">
      <iframe :src="linkMedia" allowfullscreen></iframe>
    </div>
  </div>
</template>

<script>
import Hls from "hls.js";
import Plyr from "plyr";
import get from "lodash/get";
import isMobile from "ismobilejs";
import { youtubeParser, vimeoParser } from "@/functions";

export default {
  props: {
    resource: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      player: null,
      vertical: false,
      playerOptions: {
        autoplay: false,
        invertTime: false,
        vimeo: {
          playsinline: false,
        },
        i18n: {
          speed: "Velocidade",
          quality: "Qualidade",
          qualityLabel: {
            0: "Auto",
          },
        },
        speed: {
          selected: 1,
          options: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 3],
        },
      },
    };
  },
  computed: {
    isStream() {
      return this.resource.link.includes(".m3u8");
    },
    isVimeo() {
      return this.resource.link.includes("vimeo");
    },
    isYoutube() {
      return this.resource.link.includes("youtu");
    },
    linkMedia() {
      if (this.isVimeo) {
        return `https://player.vimeo.com/video/${vimeoParser(
          this.resource.link
        )}`;
      }
      if (this.isYoutube) {
        return `https://www.youtube.com/embed/${youtubeParser(
          this.resource.link
        )}`;
      }
      return this.resource.link;
    },
  },
  mounted() {
    if (!this.isStream) {
      if (this.isVimeo && this.isYoutube) this.initPlayer();
      return;
    }

    const video = document.getElementById("lesson-video-player");

    if (
      video.canPlayType("application/vnd.apple.mpegURL") &&
      !isMobile(window.navigator).android.device
    ) {
      let source = document.createElement("source");
      source.src = this.resource.link;
      source.type = "application/vnd.apple.mpegURL";
      video.appendChild(source);
    } else if (Hls.isSupported()) {
      var config = {
        xhrSetup: function (xhr) {
          xhr.withCredentials = false;
        },
        autoStartLoad: false,
      };

      const hls = new Hls(config);
      hls.loadSource(this.resource.link);

      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        hls.startLoad();
      });

      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        const availableQualities = hls.levels.map((l) => l.height).reverse();
        this.playerOptions.quality = {
          default: isMobile.any ? 360 : 480,
          options: [0, ...availableQualities],
          forced: true,
          onChange: (e) => updateQuality(e),
        };
        this.initPlayer();
      });

      const updateQuality = (newQuality) => {
        if (newQuality === 0) {
          hls.currentLevel = -1;
        } else {
          hls.levels.forEach((level, levelIndex) => {
            if (level.height === newQuality) {
              hls.currentLevel = levelIndex;
            }
          });
        }
      };

      hls.attachMedia(video);
    }
  },
  unmounted() {
    clearInterval(this.interval);
  },
  methods: {
    initPlayer() {
      let controls = [
        "play-large",
        "play",
        "progress",
        "current-time",
        "mute",
        "volume",
        "captions",
        "settings",
        "pip",
        "airplay",
      ];
      if (!isMobile(window.navigator).any) {
        controls = ["rewind", "fast-forward", ...controls, "fullscreen"];
      }
      this.playerOptions.controls = controls;

      const video = document.getElementById("lesson-video-player");

      this.player = new Plyr(video, this.playerOptions);

      var firstPlay = true;
      this.player.on("play", () => {
        if (firstPlay) {
          setTimeout(() => {
            this.player.currentTime = get(
              this.lesson,
              "progress.stopped_at",
              0
            );
          }, 1);
          firstPlay = false;
        }
      });

      this.player.on("ready", () => {
        setTimeout(() => {
          const iframe = document.querySelector(
            "#lesson-video-player .plyr__video-wrapper"
          );
          if (iframe && iframe.offsetHeight > iframe.offsetWidth) {
            this.vertical = true;
          }
        }, 2000);
      });

      const saveCurrentPosition = () => {
        this.saveDuration({
          finished: this.player.currentTime === this.duration,
          duration: this.player.currentTime,
        });
      };

      this.interval = setInterval(() => {
        if (this.player.playing) {
          saveCurrentPosition();
        }
      }, 20 * 1000);

      this.player.on("pause", saveCurrentPosition);

      this.player.on("ended", () => {
        setTimeout(() => {
          this.saveDuration({ finished: true, duration: 0 });
        }, 200);
        setTimeout(() => {
          this.$emit("ended");
        }, 1000);
      });
    },
    saveDuration({ finished, duration }) {
      this.$store.dispatch("modules/setProgress", {
        lesson_id: this.resource.id,
        stopped_at: parseInt(duration),
        finished_at: finished,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.lesson-video-player {
  &.vertical {
    max-width: 300px;
    margin: auto;
  }
}
</style>
