<template>
  <form @submit.prevent="submit" class="lesson-comments-form">
    <div class="form-group">
      <textarea
        v-model="text"
        class="form-control"
        rows="2"
        placeholder="Escreva um comentário"
        required
      />
    </div>
    <button
      class="btn btn-primary btn-strong px-5 rounded-pill"
      :disabled="loading"
    >
      <loading :show="loading"> Enviar </loading>
    </button>
  </form>
</template>

<script>
import get from "lodash/get";

export default {
  props: {
    lesson: [String, Number],
    parent: [String, Number],
    comment: [Object],
  },
  data() {
    return {
      text: get(this.comment, "text"),
      loading: false,
    };
  },
  methods: {
    submit() {
      this.loading = true;

      const data = {
        text: this.text,
        lesson_id: this.lesson,
      };
      if (this.parent) {
        data.parent = this.parent;
      }

      let action = "comments/add";
      if (this.comment) {
        action = "comments/update";
        data.id = this.comment.id;
      }

      this.$store
        .dispatch(action, data)
        .then(() => {
          this.text = "";
          this.$message.success("Comentário salvo com sucesso");
          this.$emit("success");
        })
        .catch(() => {
          this.$message.error("Erro");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.lesson-comments-form {
  textarea {
    background: rgba(255, 255, 255, 0.03);
    border-radius: 8px;
    border: none;
    color: white;
  }
}
</style>
