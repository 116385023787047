<template>
  <action-dispatcher
    action="courses/getLesson"
    class="view-lesson"
    :parameters="$route.params.id"
  >
    <live v-if="showLive" class="container" :lesson="lesson" />

    <div v-else class="container py-5">
      <video-player v-for="video in videos" :key="video.id" :resource="video" />
      <h2 class="font-serif mt-4 mb-5">{{ lesson.name }}</h2>
      <div class="mb-5" v-html="lesson.description"></div>
      <comments :lesson="$route.params.id" />
    </div>
  </action-dispatcher>
</template>

<script>
import { mapState } from "vuex";
import { get } from "lodash";
import VideoPlayer from "@/components/lesson/VideoPlayer";
import Live from "@/components/lesson/Live";
import Comments from "@/components/lesson/Comments";

export default {
  components: { VideoPlayer, Live, Comments },
  computed: {
    ...mapState("courses", ["lesson"]),
    resourses() {
      return get(this.lesson, "resourses") || [];
    },
    videos() {
      return this.resourses.filter((r) => r.type === "videos");
    },
    showLive() {
      return (
        this.lesson.is_live || (this.videos.length === 0 && this.lesson.url)
      );
    },
  },
};
</script>
