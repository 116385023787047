<template>
  <div class="lesson-comments">
    <comments-form class="mb-5" @success="refresh(true)" :lesson="lesson" />

    <action-dispatcher
      action="comments/get"
      :parameters="{ lesson_id: lesson, limit, offset }"
      ref="commentsGet"
      always-show-slot
    >
      <comment
        v-for="comment in comments"
        :key="comment.id"
        :comment="comment"
        @edit="openEdit"
        @remove="openRemove"
        @reply="reply"
      />
    </action-dispatcher>

    <div v-if="count > comments.length" class="text-center">
      <button class="btn btn-load-more rounded-pill" @click="loadMore">
        VER MAIS
      </button>
    </div>

    <em class="text-secondary" v-if="count === 0">
      <small>Nenhum comentário enviado</small>
    </em>

    <confirmation
      v-model="removeDialog"
      :loading="loading"
      @confirm="remove"
      text="Tem certeza que deseja remover comentário?"
      confirm-button-text="Remover"
    />

    <el-dialog v-model="replyDialog" title="Responder comentário">
      <comments-form
        @success="refresh(true)"
        :parent="selectedComment"
        :lesson="lesson"
      />
    </el-dialog>

    <el-dialog v-model="editDialog" title="Editar comentário">
      <comments-form
        @success="refresh(true)"
        :comment="selectedComment"
        :lesson="lesson"
      />
    </el-dialog>
  </div>
</template>

<script>
import Comment from "./Comment";
import CommentsForm from "./CommentsForm";
import { mapState } from "vuex";

export default {
  components: { CommentsForm, Comment },
  props: {
    lesson: [String, Number],
  },
  data() {
    return {
      replyDialog: false,
      offset: 0,
      limit: 5,
      selectedComment: null,
      editDialog: false,
      removeDialog: false,
      loading: false,
    };
  },
  computed: {
    ...mapState("comments", ["comments", "count"]),
  },
  beforeMount() {
    this.$store.dispatch("comments/reset");
  },
  methods: {
    reply(comment) {
      this.selectedComment = comment.id;
      this.replyDialog = true;
    },
    remove() {
      this.loading = true;
      this.$store
        .dispatch("comments/remove", this.selectedComment.id)
        .then(() => {
          this.$message.success("Comentário removido com sucesso");
          this.refresh(true);
        })
        .catch(() => {
          this.$message.error("Erro");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openEdit(comment) {
      this.editDialog = true;
      this.selectedComment = comment;
    },
    openRemove(comment) {
      this.removeDialog = true;
      this.selectedComment = comment;
    },
    refresh(reset = false) {
      if (reset) {
        this.offset = 0;
        this.$store.dispatch("comments/reset");
      }
      this.editDialog = false;
      this.replyDialog = false;
      this.removeDialog = false;
      setTimeout(() => {
        this.$refs.commentsGet.dispatch();
      }, 1);
    },
    loadMore() {
      this.offset += this.limit;
      setTimeout(() => {
        this.refresh();
      }, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.lesson-comments {
  .btn-load-more {
    font-size: 14px;
    line-height: 21px;
    padding: 10px 32px;
    font-weight: 500;
    background: #1c1c1c;
    color: white;

    &:hover {
      background: #191919;
    }
  }
}
</style>
